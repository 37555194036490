<template>
  <div>
    <UserTransactionsTable
      :data="data ?? {}"
      :loading="loading"
      :columns="['raw_description', 'tenant_transaction_date', 'amount']"
      :rows="20"
      @updateTable="$emit('updateTable')"
      @onSortChange="($event) => $emit('onSortChange', $event)"
      @view-all-clicked="$emit('viewAllClicked')"
    />
  </div>
</template>

<script>
import UserTransactionsTable from "./UserTransactionsTable.vue";
export default {
  components: { UserTransactionsTable },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateTable", "onSortChange", "viewAllClicked"],
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
