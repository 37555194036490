<template>
  <div>
    <h4 class="lu-base-card-title m-0 p-0">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
h4.lu-base-card-title {
  color: var(--green);
  font-size: 1.4rem !important;
  font-weight: 600;
  line-height: 100%;
}
</style>
