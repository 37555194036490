<template>
  <div class="d-flex justify-content-between align-items-center gap-4">
    <div class="label">
      <div class="box"></div>
      <p>{{ label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,

      default: "",
      required: true,
    },
    color: {
      type: String,
      default: "black",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  gap: 0.35rem;

  p {
    margin: 0;
    padding: 0;
    font-size: 0.92rem;
    color: var(--DarkGreen-50, #809795);
  }
  .box {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background-color: v-bind(color);
  }
}
</style>
